/*
 *
 *   INSPINIA - Responsive Admin Theme
 *   version 2.7.1
 *
 */
$(document).ready(function () {
    $('#side-menu').metisMenu();
});
function repoFormatResult(repo) {
    if (!repo.id) {
        return repo.text;
    }
    var markup = '<div class="media">' +
        '<div class="media-left">' +
        '<a href="#"> <img class="media-object img-sm img-circle" src="'+base_url+'/'+repo.product_img+
        '" alt="' + repo.name+
        '" style="height: 20px;width:20px;">' +
        '</a>' +
        '</div>' +
        '<div class="media-body">' +
        '<div class="col-md-6 media-heading pull-left" style="padding-left: 0px"> ' +repo.name+
        '<em> &nbsp; &nbsp;( ' +  repo.description+')</em> &nbsp; &nbsp;'+
        '</div>' +
        '<div class="col-md-3 media-heading">Photo Quantity(' +repo.individual_photos_quantity+')'+'</div>' +
        '<div class="media-heading pull-right"><span><i class="fa fa-money"></i> R ' +  repo.price+'</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'+
        '</div>' +
        '</div>' +
        '</div>';

    return $(markup);
}

function repoFormatSelection(repo) {
    if(repo !==undefined && repo.id){
        return repo.name;
    }
    if(repo.price==undefined){
        repo.price = '';
    }
    return repo.name?repo.name:"Please select a product";
}


Dropzone.autoDiscover = false;
$(document).ready(function () {
    $(".fold-table tr.view").on("click", function(){
        $(this).toggleClass("open").next(".fold").toggleClass("open");
    });
 // console.log($('#total_product').val());
    // $('#mytable').DataTable({ responsive: true});
    $('#prodsTable').DataTable({ responsive: true});
    $('#photoTable').DataTable({ responsive: true});
    $('#clientsTable').DataTable({ responsive: true});
    $('#shipment_date').datetimepicker({format: 'DD/MM/YYYY'});
    $('#shoot_date').datetimepicker({format: 'DD/MM/YYYY'});
    $('#shoot_inactive_date').datetimepicker({format: 'DD/MM/YYYY'});
    $('#order_closing_date').datetimepicker({format: 'DD/MM/YYYY'});

    $(".showList").on('click',function () {
        $("#orderList").toggle();
        $("#orderInfo").css("display", "none");
    });
    $(".showInfo").on('click',function () {
        $("#orderInfo").toggle();
    });
    $(".showPhotoList").on('click',function () {
        $("#orderPhotoInfo").toggle();
    });
    // $(".showJob").on('click',function () {
    //     $("#jobInfo").toggle();
    // });
    $(".photoCheck").on('change',function () {
        $("#process").toggle(this.checked);
    });

    $("#sel-product").select2({
        placeholder: "Please select a product",
        templateResult: repoFormatResult, // omitted for brevity, see the source of this page
        templateSelection: repoFormatSelection,  // omitted for brevity, see the source of this page
        ajax: {
            url: 'get-photo-products',
            dataType: 'json',
            processResults: function (results) {
                var data = $.map(results.results, function (obj,index) {
                    obj.text = obj.text || obj.name; // replace name with the property used for the text
                    obj.description = obj.description || obj.description; // replace name with the property used for the text
                    obj.product = "Product "+(index+1); // replace name with the property used for the text

                    return obj;
                });
                results.results=data;
                return results;
            }
            // Additional AJAX parameters go here; see the end of this chapter for the full code of this example
        }
    });
    // }, 100);

    Dropzone.prototype.defaultOptions.acceptedFiles= "image/*";
    Dropzone.prototype.defaultOptions.maxFilesize= 100;

    Dropzone.options.jobsUploadIndividual = {
        maxFilesize:10,
        maxFiles:1,
        acceptedFiles: "image/*"
    };
    Dropzone.options.jobsUploadgroup = {
        maxFilesize:10,
        maxFiles:1,
        acceptedFiles: "image/*"
    };

    $('.prod_img').on('click',function () {
        $('#prodModal').css("display", "block");
        $('#prod_img01').attr('src',$(this).attr('src'));
    });

    $('.close').on('click',function () {
        $('#prodModal').css("display", "none");
    });

    $("#ckbCheckAll").click(function () {
        $(".chk").prop('checked', $(this).prop('checked'));
    });

    $('#discontinue_prod').on('click',function () {
        var checkedIds = $(".chk:checked").map(function() {
            return this.id;
        }).toArray();
        var data = 'id=' + checkedIds.join(",");
        $.ajax({
            type: "POST",
            url: "products/delete-product",
            data: data,
            success: function(response) {
                // if(response.status == 'true') {
                swal({
                    title: "Are you sure?",
                    text: "This product will be deleted!",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonClass: "btn-danger",
                    confirmButtonText: "Yes, delete it!",
                    cancelButtonText: "No!",
                    cancelButtonColor: '#d33',
                    closeOnConfirm: false,
                    closeOnCancel: false
                }).then(function (isConfirm) {
                    if (isConfirm) {
                        swal("Deleted!", "The product has been deleted.", "success");
                        location.reload();
                    }
                });
                // }
            }
        });
    });

    $('.error').hide();
    $('#prodButton').on('click', function () {
        var individualFiles = $('#my-awesome-dropzone').get(0).dropzone.getAcceptedFiles();
        $('.error').hide();
        var name = $("input#prod_name").val();
        if (name == "") {
            $("label#name_error").show();
            $("input#prod_name").focus();
            return false;
        }
        var desc = $("input#desc").val();
        if (desc == "") {
            $("label#desc_error").show();
            $("input#desc").focus();
            return false;
        }
        var price = $("input#price").val();
        if (price == "") {
            $("label#price_error").show();
            $("input#price").focus();
            return false;
        }

        var formData = new FormData();

        formData.append('prod_img', individualFiles[0]);
        var is_saleable = $('input[name="is_saleable"]:checked').val();
        var token = $("input[name=_token]").val();
        var prod_id = $("input[name=prod_id]").val();
        var individual_photos_availability =  $('input[name="individual_photos_availability"]:checked').val() ==1?1:0;
        var individual_photos_quantity = $("input#individual_photos_quantity").val();
        var group_photos_availability = $('input[name="group_photos_availability"]:checked').val() ==1?1:0;
        var group_photos_quantity = $("input#group_photos_quantity").val();

        var dataString = {'prod_name' : name, 'desc' : desc, 'price' : price, 'is_saleable' : is_saleable, 'token' : token, 'individual_photos_availability' : individual_photos_availability, 'individual_photos_quantity' : individual_photos_quantity, 'group_photos_availability' : group_photos_availability, 'group_photos_quantity' : group_photos_quantity};
        formData.append('data', JSON.stringify(dataString));

        $.ajax({
            type: "POST",
            url: "create-product",
            cache: false,
            processData: false,
            contentType: false,
            data: formData,
            success: function(response) {
                if(response.status == true) {
                    swal({
                        title: "Done!",
                        text: "Product has been created!",
                        type: "success"
                    }).then(function () {
                        location.reload();
                    });
                } else {
                    swal({
                        title: "Oops!",
                        text: "There is some error!",
                        type: "error"
                    })
                }
            }
        });
    });

    $('#photoOrdButton').on('click', function () {
        var individualFiles = $('#my-awesome-dropzone').get(0).dropzone.getAcceptedFiles();
        $('.error').hide();
        var name = $("input#order_name").val();
        if (name == "") {
            $("label#name_error").show();
            $("input#order_name").focus();
            return false;
        }
        var cont_num = $("input#cont_no").val();
        if (cont_num == "") {
            $("label#num_error").show();
            $("input#cont_no").focus();
            return false;
        }
        else {
            if (!validateContNo(cont_num)) {
                $("label#num_error").show();
                $("input#photo_no").focus();
                return false;
            }
        }

        var shipping_address = $("textarea#shipping_address").val();
        if (name == "") {
            $("label#address_error").show();
            $("input#shipping_address").focus();
            return false;
        }

        var prod_id = $(".sel-product option:selected").val();
        if(prod_id == '')
        {
            $(".product_name").html("Please select the product. ");
        }else{
            $('.product_name').html("");
        }

        if(individualFiles.length ==0)
        {
            $(".upload-individual").html("Please select an image. ");
        }else{
            $('.upload-individual').html("");
        }

        var formData = new FormData();

        formData.append('photo_img', individualFiles[0]);
        var ship_type = $('input[name="ship_type"]:checked').val();
        var token = $("input[name=_token]").val();

        var dataString = {'order_name' : name, 'cont_num' : cont_num, 'ship_type' : ship_type, 'token' : token, 'shipping_address' : shipping_address, 'prod_id' : prod_id};
        formData.append('data', JSON.stringify(dataString));

        $.ajax({
            type: "POST",
            url: "create-photo-order",
            cache: false,
            processData: false,
            contentType: false,
            data: formData,
            success: function(response) {
                if(response.status == true) {
                    swal({
                        title: "Done!",
                        text: "Order has been created!",
                        type: "success"
                    }).then(function () {
                        location.reload();
                    });
                } else {
                    swal({
                        title: "Oops!",
                        text: "There is some error!",
                        type: "error"
                    })
                }
            }
        });
    });

    $("#photo_email").keyup(function(){
        var email = $("input#photo_email").val();
        var formData = 'email=' + email;
        $.ajax({
            type: "POST",
            url: "check-email",
            data: formData,
            success: function(response) {
                console.log(response.status);
                if(response.status == true) {
                    $("label#email_dupli").show();
                    $("input#photo_email").focus();
                } else {
                    $("label#email_dupli").hide();
                }
            }
        });
    });

    $('#photoButton').on('click', function () {
        var individualFiles = $('#my-awesome-dropzone').get(0).dropzone.getAcceptedFiles();
        $('.error').hide();
        var name = $("input#photo_name").val();
        if (name == "") {
            $("label#name_error").show();
            $("input#photo_name").focus();
            return false;
        }
        var desc = $("input#photo_desc").val();
        if (desc == "") {
            $("label#desc_error").show();
            $("input#photo_desc").focus();
            return false;
        }
        var email = $("input#photo_email").val();
        if (email == "") {
            $("label#email_error").show();
            $("input#photo_email").focus();
            return false;
        }
        else {
            if (!validateEmail(email)) {
                $("label#email_error").show();
                $("input#photo_email").focus();
                return false;
            }
        }
        var cont_num = $("input#photo_no").val();
        if (cont_num == "") {
            $("label#num_error").show();
            $("input#photo_no").focus();
            return false;
        }
        else {
            if (!validateContNo(cont_num)) {
                $("label#num_error").show();
                $("input#photo_no").focus();
                return false;
            }
        }
        var password = $("input#password").val();
        if (password == "") {
            $("label#pass_error").show();
            $("input#password").focus();
            return false;
        }
        var vat = $("input#vat").val();
        if (vat == "") {
            $("label#vat_error").show();
            $("input#vat").focus();
            return false;
        }

        if(individualFiles.length ==0)
        {
            $(".upload-logo").html("Please select an image. ");
        }else{
            $('.upload-logo').html("");
        }

        if(selectedProductIds.length ==0)
        {
            $(".product_name").html("Please select the product. ");
        }else{
            $('.product_name').html("");
        }

        var formData = new FormData();

        formData.append('photo_logo', individualFiles[0]);
        var is_saleable = $('input[name="is_saleable"]:checked').val();
        var token = $("input[name=_token]").val();
        var prod_id = $("input[name=prod_id]").val();
        var individual_photos_availability =  $('input[name="individual_photos_availability"]:checked').val() ==1?1:0;
        var individual_photos_quantity = $("input#individual_photos_quantity").val();
        var group_photos_availability = $('input[name="group_photos_availability"]:checked').val() ==1?1:0;
        var group_photos_quantity = $("input#group_photos_quantity").val();

        var dataString = {'photo_name' : name, 'photo_desc' : desc, 'photo_email' : email, 'photo_no' : cont_num, 'vat' : vat, 'token' : token, 'password' : password, 'product_id': selectedProductIds};
        formData.append('data', JSON.stringify(dataString));
        $('#loader').css('display','block');
        $.ajax({
            type: "POST",
            url: "create-photographer",
            cache: false,
            processData: false,
            contentType: false,
            data: formData,
            success: function(response) {
                $('#loader').css('display','none');
                if(response.status == true) {
                    swal({
                        title: "Done!",
                        text: "Photographer has been created!",
                        type: "success"
                    }).then(function () {
                        location.reload();
                    });
                } else {
                    swal({
                        title: "Oops!",
                        text: "There is some error!",
                        type: "error"
                    })
                }
            }
        });
    });

    function validateEmail(sEmail) {
        var emailId = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if(emailId.test(sEmail)){
            return true;
        }else{
            return false;
        }
    }

    function validateContNo(sNo) {
        var filter = /^\d{10}$/;
        if(filter.test(sNo)) {
            return true;
        }
        else {
            return false;
        }
    }

    $("div#my-awesome-dropzone").dropzone({
        url: "/products/create-product",
        autoProcessQueue: false,
        maxThumbnailFilesize: 100,
        maxFilesize: 100,
        maxFiles: 1,
        addRemoveLinks: true
    });

    $('#changePwdButton').on('click', function () {
        $('.error').hide();
        var currentPwd = $("input#currentPwd").val();
        if (currentPwd == "") {
            $("label#currentPwd_error").show();
            $("input#currentPwd").focus();
            return false;
        }
        var newPwd = $("input#newPwd").val();
        if (newPwd == "") {
            $("label#newPwd_error").show();
            $("input#newPwd").focus();
            return false;
        }
        var newRePwd = $("input#newRePwd").val();
        if (newRePwd == "") {
            $("label#newRePwd_error").show();
            $("input#newRePwd").focus();
            return false;
        }
        var token = $("input[name=_token]").val();

        var formData = new FormData();

        var dataString = {'password' : newPwd , 'token' : token};
        formData.append('data', JSON.stringify(dataString));

        $.ajax({
            type: "POST",
            url: "update-password",
            cache: false,
            processData: false,
            contentType: false,
            data: formData,
            success: function(response) {
                if(response.status == true) {
                    swal({
                        title: "Done!",
                        text: "Password has been updated!",
                        type: "success"
                    }).then(function () {
                        location.reload();
                    });
                } else {
                    swal({
                        title: "Oops!",
                        text: "There is some error!",
                        type: "error"
                    })
                }
            }
        });
    });

    $("#currentPwd").keyup(function(){
        var currentPwd = $("input#currentPwd").val();
        var formData = 'password=' + currentPwd;
        $.ajax({
            type: "POST",
            url: "check-password",
            data: formData,
            success: function(response) {
                console.log(response.status);
                if(response.status == false) {
                    $("label#pwd_exists").show();
                    $("input#currentPwd").focus();
                } else {
                    $("label#pwd_exists").hide();
                }
            }
        });
    });

    $('#newPwd, #newRePwd').on('keyup', function () {
        if ($('#newPwd').val() == $('#newRePwd').val()) {
            $('.message').css('display', 'none');
        } else
            $('.message').html('Password is not matching').css({"color": "red", "display": "block"});
    });

    // Add body-small class if window less than 768px
    if ($(this).width() < 769) {
        $('body').addClass('body-small')
    } else {
        $('body').removeClass('body-small')
    }

    // MetsiMenu
    $('#side-menu').metisMenu();

    // Collapse ibox function
    $('.collapse-link').on('click', function () {
        var ibox = $(this).closest('div.ibox');
        var button = $(this).find('i');
        var content = ibox.children('.ibox-content');
        content.slideToggle(200);
        button.toggleClass('fa-chevron-up').toggleClass('fa-chevron-down');
        ibox.toggleClass('').toggleClass('border-bottom');
        setTimeout(function () {
            ibox.resize();
            ibox.find('[id^=map-]').resize();
        }, 50);
    });

    // Close ibox function
    $('.close-link').on('click', function () {
        var content = $(this).closest('div.ibox');
        content.remove();
    });

    // Fullscreen ibox function
    $('.fullscreen-link').on('click', function () {
        var ibox = $(this).closest('div.ibox');
        var button = $(this).find('i');
        $('body').toggleClass('fullscreen-ibox-mode');
        button.toggleClass('fa-expand').toggleClass('fa-compress');
        ibox.toggleClass('fullscreen');
        setTimeout(function () {
            $(window).trigger('resize');
        }, 100);
    });

    // Minimalize menu
    $('.navbar-minimalize').on('click', function (event) {
        event.preventDefault();
        $("body").toggleClass("mini-navbar");
        SmoothlyMenu();

    });

    // Full height of sidebar
    function fix_height() {
        var heightWithoutNavbar = $("body > #wrapper").height() - 61;
        $(".sidebard-panel").css("min-height", heightWithoutNavbar + "px");

        var navbarHeight = $('nav.navbar-default').height();
        var wrapperHeight = $('#page-wrapper').height();

        if (navbarHeight > wrapperHeight) {
            $('#page-wrapper').css("min-height", navbarHeight + "px");
        }

        if (navbarHeight < wrapperHeight) {
            $('#page-wrapper').css("min-height", $(window).height() + "px");
        }

        if ($('body').hasClass('fixed-nav')) {
            if (navbarHeight > wrapperHeight) {
                $('#page-wrapper').css("min-height", navbarHeight + "px");
            } else {
                $('#page-wrapper').css("min-height", $(window).height() - 60 + "px");
            }
        }

    }

    fix_height();

    // Fixed Sidebar
    $(window).bind("load", function () {
        if ($("body").hasClass('fixed-sidebar')) {
            $('.sidebar-collapse').slimScroll({
                height: '100%',
                railOpacity: 0.9
            });
        }
    });

    $(window).bind("load resize scroll", function () {
        if (!$("body").hasClass('body-small')) {
            fix_height();
        }
    });

    // Add slimscroll to element
    $('.full-height-scroll').slimscroll({
        height: '100%'
    })
});


// Minimalize menu when screen is less than 768px
$(window).bind("resize", function () {
    if ($(this).width() < 769) {
        $('body').addClass('body-small')
    } else {
        $('body').removeClass('body-small')
    }
});

function SmoothlyMenu() {
    if (!$('body').hasClass('mini-navbar') || $('body').hasClass('body-small')) {
        // Hide menu in order to smoothly turn on when maximize menu
        $('#side-menu').hide();
        // For smoothly turn on menu
        setTimeout(
            function () {
                $('#side-menu').fadeIn(400);
            }, 200);
    } else if ($('body').hasClass('fixed-sidebar')) {
        $('#side-menu').hide();
        setTimeout(
            function () {
                $('#side-menu').fadeIn(400);
            }, 100);
    } else {
        // Remove all inline style from jquery fadeIn function to reset menu state
        $('#side-menu').removeAttr('style');
    }
}



function addClient(){
    console.log('here');
    var token = $('#client').attr('data-token');
    swal({
        title: 'Enter the Client Name',
        html:'<div><input id="client-name" type="text" class="form-control input-sm" ' +
        'style="margin-top:20px;height: 38px;font-size: 15px"><br><select id="client-type" class="form-control input-sm" ' +
        'style="height: 38px;margin-bottom:-50px;font-size: 15px;cursor:pointer;padding-top:10px"><option style="padding-top:10px" ' +
        'value="">-- Select Client Type --</option><option style="padding-top:10px" value="school">School</option>' +
        '<option style="padding-top:10px" value="wedding">Wedding</option><option value="corporate">Corporate</option><option style="padding-top:10px" value="lifestyle">Lifestyle</option><option style="padding-top:10px" value="studio">Studio</option></select></div>',
        showCancelButton: true,
        confirmButtonText: 'Submit',
        cancelButtonColor: '#d33',
        showLoaderOnConfirm: true,
        preConfirm: function (text) {
            return new Promise(function (resolve, reject) {
                setTimeout(function() {
                    if(text ==''){
                        reject('Please enter client name.')
                    }else {
                        resolve()
                    }
                }, 20)
            })
        },
        allowOutsideClick: false
    }).then(function (text) {
        // alert($('#client-type').val());
        text = $('#client-name').val();
        $.ajax(
            {  type: 'post',
                url: base_url+"/jobs/add-client",
                data: {
                    "client_name": $('#client-name').val(),
                    "client_type": $('#client-type').val(),
                    "_token": token,
                },success:function(result) {
                // console.log(result);
                if(result.status){
                    swal({
                        text: result.message,
                        type: 'success',
                    }).then(function(isConfirm) {
                        var $dropdown = $("#dropdown");
                        $dropdown.append($("<option />").val(result.id).text(text));
                    });
                }else{
                    swal({
                        text: result.message,
                        type: 'error',
                    });
                }
            }
            })
    })
}


function toggle(source) {
    var checkboxes = document.querySelectorAll('input[type="checkbox"]');
    for (var i = 0; i < checkboxes.length; i++) {
        if (checkboxes[i] != source)
            checkboxes[i].checked = source.checked;
    }
}

var selectedProductIds =[];

function addProduct(){

    if($('input[name="product"]:checked').length ==0){
       $('#errorMessage').css('display','block');
        return;
    }
    selectedProductIds =[];
    $("#product_table").find("tr:gt(0)").remove();
    var tableControl= document.getElementById('mytable');
    $("#no_value").css('display','none');
   var thead = document.getElementById("mytable").tHead.innerHTML;
    $("#product_table thead").append(thead);
    $('input:checkbox:checked', tableControl).each(function() {
        var id =  $(this).closest('tr').find('input:checkbox').attr('data-id');
        if(id !=0){
            selectedProductIds.push($(this).closest('tr').find('input:checkbox').attr('data-id'));
            var markup ='<tr>'+$(this).closest('tr').html()+'</tr>';
            // console.log(markup);
            $("#product_table tbody").append(markup);
        }
    }).get();
    $('#myModal').modal('hide');
    // console.log(selectedProductIds);
}

$('.check-link').click(function() {
    $('#errorMessage').css('display','none');
    if($('input[name="product"]:checked').length ==0){
        $('#errorMessage').css('display','block');
    }
});

function deleteProduct(){

    if($('#product_table input[name="product"]:checked').length ==0){     //console.log('here');
        return;
    }
    // $("#product_table").find("tr:gt(0)").remove();
    var tableControl= document.getElementById('product_table');
    $('input:checkbox:checked', tableControl).each(function() {
         var id =  $(this).closest('tr').find('input:checkbox').attr('data-id');
        $('.product_'+id).prop('checked', false);
        selectedProductIds.pop(id);
       $(this).closest('tr').remove();
    }).get();
    // console.log(selectedProductIds);
  if(selectedProductIds.length ==0){
      $("#no_value").css('display','block');
  }
}

// $("div#jobs-upload-individual").dropzone({
//     url: "/jobs/upload-individual",
//     previewsContainer: "#previews",
//     autoProcessQueue:false
// });
//
// $("div#jobs-upload-group").dropzone({
//     url: "/jobs/upload-group",
//     autoProcessQueue:false
// });

$('#submitJob').on('click', function() {

    var status =true;
    if($('select[name=client_name]').val() ==''){
        $('.client_name').html("Please select client.");
        status = false;
    }else{
        $('.client_name').html("");
    }

    if($("#shoot_name").val()==="")
    {
        $(".shoot_name").html("Please enter the shoot name. ");
        status = false;
    }else{
        $('.shoot_name').html("");
    }

    if($('input[name=shoot_date]').val() =="")
    {
        $(".shoot_date").html("Please select the shoot date. ");
        status = false;
    }else{
        $('.shoot_date').html("");
    }

    if($('input[name=order_closing_date]').val() =="")
    {
        $(".order_closing_date").html("Please select the initial order closing date. ");
        status = false;
    }else{
        $('.order_closing_date').html("");
    }

    if($('input[name=shipment_date]').val() =="")
    {
        $(".shipment_date").html("Please select the initial shipment date. ");
        status = false;
    }else{
        $('.shipment_date').html("");
    }

    if($('input[name=shoot_inactive_date]').val() =="")
    {
        $(".shoot_inactive_date").html("Please select the initial shipment date. ");
        status = false;
    }else{
        $('.shoot_inactive_date').html("");
    }


    if(selectedProductIds.length ==0)
    {
        $(".product_name").html("Please select the product. ");
        status = false;
    }else{
        $('.product_name').html("");
    }

    // var individualFiles = $('#jobs-upload-individual').get(0).dropzone.getAcceptedFiles();
    // var groupFiles = $('#jobs-upload-group').get(0).dropzone.getAcceptedFiles();
    //
    // if(individualFiles.length ==0)
    // {
    //     $(".upload-individual").html("Please select atleast one image. ");
    //     status = false;
    // }else{
    //     $('.upload-individual').html("");
    // }
    //
    // if(groupFiles.length ==0)
    // {
    //     $(".upload-group").html("Please select atleast one image. ");
    //     status = false;
    // }else{
    //     $('.upload-group').html("");
    // }

    if(status){
        $('html, body').animate({
            'scrollTop' : $("#createJob").position().top
        });
        $('#loader').css('display','block');

        var requestData = {
            'client_id': $('select[name=client_name]').val(),
            'shoot_name': $('input[name=shoot_name]').val(),
            'shoot_date': $('input[name=shoot_date]').val(),
            'order_closing_date': $('input[name=order_closing_date]').val(),
            'shipment_date': $('input[name=shipment_date]').val(),
            'shoot_inactive_date': $('input[name=shoot_inactive_date]').val(),
            'individual_privacy': $('input[name=individual_privacy]:checked').val(),
            'group_privacy': $('input[name=group_privacy]:checked').val(),
            'product_id': selectedProductIds
        };

        // console.log(requestData);
        var formData = new FormData();
        formData.append('_token', $('#jobForm').attr('token'));
        // $.each(individualFiles, function( index, value ) {
        //     formData.append('individual[]', value);
        // });
        // $.each(groupFiles, function( index, value ) {
        //     formData.append('group[]', value);
        // });
        // formData.append('group', groupFiles);
        formData.append('data', JSON.stringify(requestData));

        $.ajax(
            {
                type: 'POST',
                url:base_url+ "/jobs/store",
                cache: false,
                processData: false,
                contentType: false,
                data: formData,
                // async: false,
                success: function (result) {
                    $('#loader').css('display','none');
                    if(result.status){
                        swal({
                            title:'Job Added Successfully',
                            text: 'Please upload your images',
                            type: 'success',
                        }).then(function(isConfirm) { //console.log(result.jobId);
                            $("#jobForm :input").prop("disabled", true);
                            $("#submitJob").attr("disabled", "disabled");
                            $("#job_id").val(result.jobId);
                            $('#uploadImages').css('display','block');
                            $('html, body').animate({
                                'scrollTop' : $("#uploadImages").position().top
                            });
                        });

                    }else{
                        swal({
                            text: 'Something Went Wrong. Please try again',
                            type: 'error',
                        });
                    }
                }

            });
    }else{
        return false;
    }


});

function showDetail(jobId)
{
    $('#loader').css('display','block');
    $('.chk').attr('checked',false);
    $('#'+jobId).attr('checked',true);
    $.ajax(
        {  type: 'get',
            url: base_url+"/jobs/detail",
            data: {
                "job_id": jobId
            },success:function(result) {
            $("#jobInfo").show();
            $("#jobDetail").html(result);
            setTimeout(function(){
                $('#loader').css('display','none');
                $('html, body').animate({
                    'scrollTop' : $("#jobInfo").position().top
                });
             }, 1000);

        }
        })
}

$('#inactive_job').on('click',function () {
    $('#loader').css('display','block');
    var checkedIds = $(".chk:checked").map(function() {
        return this.id;
    }).toArray();

    // alert(checkedIds.length);
    if(checkedIds.length ==0){
        swal({
            text: 'Please select job to proceed',
            type: 'warning',
        });
        return false;
    }

    // console.log(checkedIds)
    swal({
        title: "Are you sure?",
        text: "You want to inactive selected job.",
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn-danger",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        cancelButtonColor: '#d33',
        closeOnConfirm: false,
        closeOnCancel: false
    }).then(function (isConfirm) {
        var ids = checkedIds.length>0?checkedIds.join(","):'';
        $.ajax({
            type: "get",
            url: base_url+"/jobs/delete",
            data: {"id":ids },
            success: function(response) {
                $('#loader').css('display','none');
                if(response.status){
                    swal("Deleted!", "The product has been deleted.", "success");
                    location.reload();
                }else{
                    swal({
                        text: 'Something Went Wrong. Please try again',
                        type: 'error',
                    });
                }

            }
        });
    });

});


var triggerMe = function(id){
    $('#'+id).trigger('click');
    //$('#'+id).css('pointer-events', 'all');
    // $("#qty").css('display','none');
}


$('#submitfiles').click(function(){
    $('.dz-remove').hide();
});

$('#submitGroupFiles').click(function(){
    $('.dz-remove').hide();
});



var zoomCheck = false;
function addImage(img){
    /*
     if(!zoomCheck){
     var image_name=$(img).attr('src');
     //console.log(image_name);
     var imageTag='<div style="position:absolute; class="zoomPic">'+'<img src="'+image_name+'" alt="image" style="width: 200%;" />'+'</div>';
     $(img).parent('div').parent('div').append(imageTag);
     zoomCheck = true;
     }
     */
    var image_name=$(img).attr('alt');
    //console.log(image_name);
    var imageTag='<div style="width:200% class="zoomPic">'+'<img src="'+image_name+'" alt="image" style="width: 90%;margin: 5%" />'+'</div>';
    $('.imageContent').html(imageTag);
}


function removeImage(img){
    zoomCheck = false;
    $('.zoomPic').remove();
}


var individualNextpage =2;
function loadIndividualImage(jobId,totalPage){
    // console.log(jobId); console.log(totalPage);
 if(individualNextpage <= totalPage ){
     $.ajax({
         type: "get",
         url: base_url+'/jobs/individual-image?page='+ individualNextpage+'&job_id='+jobId,
         success: function(response) {
             if(response.status){
                 response.images.forEach(function(element) { console.log(element);
                     var html ='<div class="col-sm-3" class="" style="margin-bottom: 8px;"><img class="img-thumbnail" src="'+element+'"style="height: 150px;width: 250px;"></div>'
                     $('#individual_photos').append(html);
                     individualNextpage = response.current_page+1;
                     if(individualNextpage >=totalPage){
                         $("#loadMoreIndividual").hide();
                     }
                 });
             }

         }
     });
 }

}

var groupNextpage =2;
function groupImage(jobId,totalPage){

    if(groupNextpage <= totalPage){
        $.ajax({
            type: "get",
            url: base_url+'/jobs/group-image?page='+ groupNextpage+'&job_id='+jobId,
            // data: {"job_id":jobId,"page":individualCurrentpage },
            success: function(response) {
                if(response.status  ){
                    response.images.forEach(function(element) {
                       var url = base_url+'/'+element;
                        element= "'"+element+"'";

                        var html ='<div class="col-sm-3" class="" style="margin-bottom: 8px;"><img class="img-thumbnail" src="'+url+'" onclick="showImage('+element+')"  style="height: 150px;width: 250px;"></div>';
                        $('#group_photos').append(html);
                        groupNextpage = response.current_page+1;
                        if(groupNextpage >=totalPage){
                            $("#loadMoreGroup").hide();
                        }
                    });
                }

            }
        });
    }

}

function showImage(src){
    var imgUrl = base_url+'/'+src;console.log(imgUrl);

    swal({
        // title:'<img onclick="swal.close();" style="padding-left:100%;'+closeButton+'" src="https://maps.gstatic.com/intl/en_us/mapfiles/iw_close.gif">',
        html:  "<img src='" + imgUrl + "' style='width:100%;'>",
        showConfirmButton: false,
        customClass: 'swal-wide',
    });
}

function getItems(id){
    $.ajax({
        type: "get",
        url: base_url+'/orders/images',
        data: {"order_product_id":id },
        success: function(response) {
           $('#item_'+id).html(response);
        }
    });
}

function zoomImage(src){
    $('#prodModal').css("display", "block");
    $('#prod_img01').attr('src',src);
}

function changeStatus(orderId)
{
    $.ajax({
        type: "get",
        url: base_url+'/orders/update-status',
        data: {"order_id":orderId },
        success: function(response) {
           if(response.status){
               $("#"+orderId).html('<i class="fa fa-check fa-lg" style="color: #00cf00;"></i>');
           }
        }
    });
}

function changeProductStatus(orderProductId,orderId)
{
    $.ajax({
        type: "get",
        url: base_url+'/orders/update-product-status',
        data: {"order_product_id":orderProductId,"order_id":orderId },
        success: function(response) {
            if(response.status){
                $("#"+orderProductId).html('<i class="fa fa-check fa-lg" style="color: #00cf00;"></i>');
            }
        }
    });
}
//Utkarsh
function showCartItemCount()
{
    $('#cartCount').html("...");
    $.ajax(
        {  type: 'get',
            url: "get-cart-items",
            data: {
            },success:function(result) {
            $('#cartCount').html(result);
        }
        })
}
$('.add-to-cart').on('click', function () {
    if(currentFileNum>0) {
        $("#addToCart,#checkout").prop('disabled', false);
    }
});

var validatecontno = function(sNo) {
    var filter = /^\d{10}$/;
    if(filter.test(sNo)) {
        return true;
    }
    else {
        return false;
    }
};
$('#photoCartButton').on('click', function () {
    // var individualFiles = $('#my-awesome-dropzone').get(0).dropzone.getAcceptedFiles();
    $('.error').hide();
    // var name = $("input#order_name").val();
    /*if (name == "") {
     $("label#name_error").show();
     $("input#order_name").focus();
     return false;
     }
     var cont_num = $("input#cont_no").val();
     if (cont_num == "") {
     $("label#num_error").show();
     $("input#cont_no").focus();
     return false;
     }
     else {
     if (!validatecontno(cont_num)) {
     $("label#num_error").show();
     $("input#photo_no").focus();
     return false;
     }
     }*/

    // var shipping_address = $("textarea#shipping_address").val();
    /* if (name == "") {
     $("label#address_error").show();
     $("input#shipping_address").focus();
     return false;
     }*/

    var prod_id = $(".sel-product option:selected").val();
    if(prod_id == '')
    {
        $(".product_name").html("Please select the product. ");
    }else{
        $('.product_name').html("");
    }

    /*if(individualFiles.length ==0)
     {
     $(".upload-individual").html("Please select an image. ");
     }else{
     $('.upload-individual').html("");
     }*/

    // var formData = new FormData();

    // formData.append('photo_img', individualFiles[0]);*/
    // var ship_type = $('input[name="ship_type"]:checked').val();
    var token = $("input[name=_token]").val();

    /*var dataString = {'order_name' : name, 'cont_num' : cont_num, 'ship_type' : ship_type, 'token' : token, 'shipping_address' : shipping_address, 'prod_id' : prod_id};*/
    var dataString = {'token' : token,'prod_id' : prod_id};
    // formData.append('data', JSON.stringify(dataString));
    $('#loader').css('display','block');
    $.ajax({
        type: "POST",
        url: "add-cart-detail",
        data: dataString,
        success: function(response) {
            $('#loader').css('display','none');
            // $('#img-box').css("display","block")
            $("#addToCart").attr("disabled", "disabled");
            // $("#job_id").val(response.id);
            /*swal({
             title: "Done!",
             text: "Product has been created!",
             type: "success"
             }).then(function () {
             if(response.cartItemCount){
             $('#cartCount').html(response.cartItemCount);
             }
             // location.reload();
             });*/
        }
    });
});
var data = $("#sel-product option:selected").val();
if(data==undefined){
    $(".add-to-cart").attr("disabled","disabled");
}
$('#sel-product').on('change', function() {
    var data = $("#sel-product option:selected").val();
    $(".add-to-cart").prop('disabled', false);
    $("#job_id").val(data);
})
$("#addToCart,#checkout").attr("disabled", "disabled");

var cartCount = $("#cartCount").text();
if(cartCount==0){
    $("#cartBtn").attr("disabled", "disabled");
}else{
    $("#cartBtn").prop('disabled', false);
}
$('#addToCart,#checkout').on('click', function () {
    if(currentFileNum==0){
        swal({
            text: 'Please upload images to proceed',
            type: 'warning',
        });
        return;
    }else {
        var pId = $('#job_id').val();
        var btnId = this.id;
        var prod_id = $(".sel-product option:selected").val();
        if (prod_id == '') {
            $(".product_name").html("Please select the product. ");
        } else {
            $('.product_name').html("");
        }
        var token = $("input[name=_token]").val();
        $('#job_id').val(pId);
        $('#loader').css('display','block');
        $.ajax({
            type: "POST",
            url: "add-to-cart",
            data: {"is_active": 1, "id": pId},
            success: function (response) {
                $('#loader').css('display','none');
                swal({
                    title: "Done!",
                    text: "Item successfully added to cart!",
                    type: "success"
                }).then(function () {
                    if (btnId != 'checkout') {
                        location.reload();
                    } else {
                        window.location = base_url + "/photographers/view-cart";
                    }
                });
            }
        });
    }
});

function requestCreator(requestedData, url, type, msg){
    type = type ? type : "POST";
    msg  = msg  ? msg  : "Something went wrong";
    var dataToReturn = '';
    $.ajax({
        type: type,
        url: url,
        async:false,
        data: requestedData,
        success: function(response) {
            // console.log(response);
            dataToReturn = response;
            // console.log(dataToReturn);

        }
    }).fail(function() {
        swal({
            title: "Done!",
            text: msg,
            type: "error"
        }).then(function () {
            // location.reload();
        });
    }).then(function (dataToReturn) {
        // return dataToReturn;
    });
    return dataToReturn;
}

$("#addressUpdate").on('click',function () {

    var text = $('#addressText').text();

    if(text=="Edit"){

        $('#address').css("pointer-events","auto")
        $('#addressUpdate i').removeClass('fa-edit');
        $('#addressUpdate i').addClass('fa-save');
        $('#addressText').html("Update");

    }else if(text=="Update"){

        $('#address').css("pointer-events","none")
        $('#addressText').html("Edit");
        $('#addressUpdate i').removeClass('fa-save');
        $('#addressUpdate i').addClass('fa-edit');
        var address = $('#address').val();
        var requestedData = {description: address};
        var response = {};
        response     = requestCreator(requestedData,"update-address");
        swal({
            title: "Done!",
            text : response.message,
            type : "success"
        }).then(function () {
            // location.reload();
        });
    }
})

/*$("#sel-product").select2({
 placeholder: "Please select a product",
 templateResult: repoFormatResult, // omitted for brevity, see the source of this page
 templateSelection: repoFormatSelection,  // omitted for brevity, see the source of this page
 ajax: {
 url: 'get-photo-products',
 dataType: 'json',
 processResults: function (results) {
 var data = $.map(results.results, function (obj,index) {
 obj.text = obj.text || obj.name; // replace name with the property used for the text
 obj.description = obj.description || obj.description; // replace name with the property used for the text
 obj.product = "Product "+(index+1); // replace name with the property used for the text

 return obj;
 });
 results.results=data;
 return results;
 }
 // Additional AJAX parameters go here; see the end of this chapter for the full code of this example
 }
 });*/

$("#cart").on("submit",function (e) {

    e.preventDefault();
    var totalPrice    = $("#totPrice").val();
    var totalTax    = $("#totTax").val();
    var address       = $("#address").val();
    var contNum       = $("#contNum").val();
    var orderName     = $("#orderName").val();
    var requestedData = {
        'shipping_address' : address,
        'contact_no'       : contNum,
        'order_name'       : orderName,
        'total_price'      : totalPrice,
        'total_tax'        : totalTax
    }
    var response = {};
    response     = requestCreator(requestedData,"submit-order");
    if(response.status) {
        swal({
            title: "Done!",
            text: response.message,
            type: "success"
        }).then(function () {
            window.location = base_url+"/photographers-orders";
        });
    }

});

function getPhotoItems(cartId, info, orderId){
    var requestedData = {"photographer_cart_id":cartId,"info":info, "order_id":orderId }
    var url = base_url+"/photographers/cart-images";
    var response = requestCreator(requestedData, url, "get");
    $('#pitem_'+cartId).html(response);
}
function removeCartItem(cartId, imageList){
    swal({
        title: "Are you sure?",
        text: "This item will be deleted!",
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn-danger",
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No!",
        cancelButtonClass:"noCl",
        cancelButtonColor: '#d33',
        closeOnConfirm: false,
        closeOnCancel: false,
    }).then(function (isConfirm) {
        if (isConfirm) {
            var requestUrl = base_url+"/photographers/remove-cart";
            var requestedData = { "photographer_cart_id":cartId,"images-list": imageList }
            var response      = requestCreator(requestedData, requestUrl);

            if(response.status) {
                swal("Deleted!", "The item has been deleted.", "success").then(function (confirm) {
                    if(confirm){
                        location.reload();
                    }
                });
            }
        }

    });
    $('.noCl').click(function () {
        location.reload();
    })
}
var uploadQty = 10000;
$(".addImg").prop('disabled', true);
$('.sel2-product').on('change', function() {
    if(currentFileNum!=0){
        $("#dropzonePreview").find(".dz-remove").each(function () {
            // console.log(this);
            this.click();
        })
    }
    $(".addImg").prop('disabled', false);
    var data = $("#sel-product option:selected").val();
    var requestedData = {"pid":data}
    uploadQty = requestCreator(requestedData,"get-upload-qty","get");
    uploadQty = uploadQty.individual_photos_quantity;
    // count     = uploadQty;
    // $("#qty").css("display","block");
    // $("#qty").text("This product can have maximum "+uploadQty+" uploads");
    // Dropzone.options.jobsUploadIndividual.maxFiles = uploadQty;
    // $('.dropzone').attr('data-count', uploadQty);
})
function logout(user) { console.log(user);
    var loginUrl='';
    swal({
        title: "Are you sure?",
        text: "You will be logged out",
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn-danger",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        cancelButtonColor: '#d33',
        closeOnConfirm: false,
        closeOnCancel: false,
    }).then(function (isConfirm) { console.log('here');
        var requestUrl = '';
        if (isConfirm) {
            if(user=='photographer'){
                requestUrl = base_url+'/logout';
                loginUrl   = '/login';
            }else if(user=='admin'){
                requestUrl = base_url+'/admin/logout';
                loginUrl   = '/admin';
            }
            // var requestedData = {  };
            // var response      = requestCreator(requestedData, requestUrl,"GET");
            // if(response) {
            // console.log(requestUrl);return false;
                window.location = requestUrl;
            // }
        }
    });
}
$('.addImg').attr("disabled","disabled");
//Utkarsh
function processPhotographerOrder(id){
    // var checkedIds = $(".orderCheck:checked").map(function() {
    //     return this.id;
    // }).toArray();
    //
    // if(checkedIds.length ==0){
    //     swal({
    //         text: 'Please select order to proceed',
    //         type: 'warning',
    //     });
    //     return false;
    // }

    // console.log(id)
    $('#loader').css('display','block');
    swal({
        title: "Are you sure?",
        text: "You want to process selected order.",
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn-danger",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        cancelButtonColor: '#d33',
        closeOnConfirm: false,
        closeOnCancel: false
    }).then(function (isConfirm) {
        // var ids = checkedIds.length>0?checkedIds.join(","):'';
        $.ajax({
            type: "get",
            url: base_url+"/photographers/order/process",
            data: {"id":id },
            success: function(response) {
                $('#loader').css('display','none');
                if(response.status){
                    swal("Success!", "The Order has been process successfully.", "success");
                    location.reload();
                }else{
                    swal({
                        text: 'Something Went Wrong. Please try again',
                        type: 'error',
                    });
                }

            }
        });
    });
}

$('#deletePhotographer').on('click',function () {
    var checkedIds = $(".chk:checked").map(function() {
        return this.id;
    }).toArray();

    // alert(checkedIds);
    if(checkedIds.length ==0){
        swal({
            text: 'Please select atleast one photographer to proceed',
            type: 'warning',
        });
        return false;
    }

    // console.log(checkedIds)
    swal({
        title: "Are you sure?",
        text: "You want to delete selected photographer.",
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn-danger",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        cancelButtonColor: '#d33',
        closeOnConfirm: false,
        closeOnCancel: false
    }).then(function (isConfirm) {
        $('#loader').css('display','block');
        var ids = checkedIds.length>0?checkedIds.join(","):'';
        $.ajax({
            type: "get",
            url: base_url+"/photographers/delete",
            data: {"id":ids },
            success: function(response) {
                $('#loader').css('display','none');
                if(response.status){
                    swal("Deleted!", "The photographer has been deleted.", "success");
                    location.reload();
                }else{
                    swal({
                        text: 'Something Went Wrong. Please try again',
                        type: 'error',
                    });
                }

            }
        });
    });

});

function addJobProduct(){

    if($('input[name="product"]:checked').length ==0){
        $('#errorMessage').css('display','block');
        return;
    }

    selectedProductIds =[];
    $("#product_table tbody").find("tr").remove();
    var tableControl= document.getElementById('mytable1');
    $("#no_value").css('display','none');
    $('input:checkbox:checked', tableControl).each(function() {
        var id =  $(this).closest('tr').find('input:checkbox').attr('data-id');
        if(id !=0){
            selectedProductIds.push($(this).closest('tr').find('input:checkbox').attr('data-id'));
            var markup ='<tr>'+$(this).closest('tr').html()+'</tr>';
            // console.log(markup);
            $("#product_table tbody").append(markup);
        }
    }).get();
    $('#myModal').modal('hide');
}

function deleteJobProduct(){

    if($('#product_table tbody input[name="product"]:checked').length ==0){     //console.log('here');
        return;
    }
    // $("#product_table").find("tr:gt(0)").remove();
    var tableControl= document.getElementById('product_table');
    $('input:checkbox:checked', tableControl).each(function() {
        var id =  $(this).closest('tr').find('input:checkbox').attr('data-id');
        selectedProductIds.pop(id);
        // console.log(this);

        if(!$(this).is(':disabled')){
            $('.product_'+id).prop('checked', false);
            $(this).closest('tr').remove();
        }

    }).get();
    // console.log(selectedProductIds);
    if(selectedProductIds.length ==0){
        $("#no_value").css('display','block');
    }
}

$('#updateJob').on('click', function() {

    var status =true;
    if($('#client_name').val() ==''){
        $('.client_name').html("Please select client.");
        status = false;
    }else{
        $('.client_name').html("");
    }

    if($("#shoot_name").val()==="")
    {
        $(".shoot_name").html("Please enter the shoot name. ");
        status = false;
    }else{
        $('.shoot_name').html("");
    }

    if($('input[name=shoot_date]').val() =="")
    {
        $(".shoot_date").html("Please select the shoot date. ");
        status = false;
    }else{
        $('.shoot_date').html("");
    }

    if($('input[name=order_closing_date]').val() =="")
    {
        $(".order_closing_date").html("Please select the initial order closing date. ");
        status = false;
    }else{
        $('.order_closing_date').html("");
    }

    if($('input[name=shipment_date]').val() =="")
    {
        $(".shipment_date").html("Please select the initial shipment date. ");
        status = false;
    }else{
        $('.shipment_date').html("");
    }

    if($('input[name=shoot_inactive_date]').val() =="")
    {
        $(".shoot_inactive_date").html("Please select the initial shipment date. ");
        status = false;
    }else{
        $('.shoot_inactive_date').html("");
    }


   /* if(selectedProductIds.length ==0)
    {
        $(".product_name").html("Please select the product. ");
        status = false;
    }else{
        $('.product_name').html("");
    }*/

    if(status){
        $('html, body').animate({
            'scrollTop' : $("#createJob").position().top
        });
        $('#loader').css('display','block');

        var requestData = {
            'job_id': $('#job_id').val(),
            'client_id': $('#client_name').val(),
            'shoot_name': $('input[name=shoot_name]').val(),
            'shoot_date': $('input[name=shoot_date]').val(),
            'order_closing_date': $('input[name=order_closing_date]').val(),
            'shipment_date': $('input[name=shipment_date]').val(),
            'shoot_inactive_date': $('input[name=shoot_inactive_date]').val(),
            'individual_privacy': $('input[name=individual_privacy]:checked').val(),
            'group_privacy': $('input[name=group_privacy]:checked').val(),
            'product_id': selectedProductIds
        };

        console.log(requestData);
        var formData = new FormData();
        formData.append('_token', $('#updateJobForm').attr('token'));
        formData.append('data', JSON.stringify(requestData));

        $.ajax(
            {
                type: 'POST',
                url:base_url+ "/jobs/update-job",
                cache: false,
                processData: false,
                contentType: false,
                data: formData,
                // async: false,
                success: function (result) {
                    $('#loader').css('display','none');
                    if(result.status){
                        swal({
                            title:'Job Updated Successfully',
                            text: 'Please upload more images',
                            type: 'success',
                        }).then(function(isConfirm) { //console.log(result.jobId);
                            // $("#job_id").val(result.jobId);
                            if(selectedProductIds.length>0){
                                $.each(selectedProductIds, function( index, value ) {
                                    $('.product_'+value).prop('checked', true);
                                    $('.product_'+value).prop('disabled', true);
                                });
                            }
                            $('#uploadImages').css('display','block');
                            $('html, body').animate({
                                'scrollTop' : $("#jumpTop").position().top
                            });
                        });

                    }else{
                        swal({
                            text: 'Something Went Wrong. Please try again',
                            type: 'error',
                        });
                    }
                }

            });
    }else{
        return false;
    }


});

function showProduct(count){
if(count>0){
    if ( ! $.fn.DataTable.isDataTable( '#mytable1' ) ) {
        $('#mytable1').DataTable({ responsive: true});
    }

}
}